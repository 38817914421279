import React, {Component} from "react";
import { Helmet } from "react-helmet";
import he from "he";

class MetaData extends Component {

    getSchema(){
        const schemaOrgJSONLD = [
            {
                "@context": "http://schema.org",
                "@type": "LocalBusiness",
                name: process.env.GATSBY_BUSINESS_NAME,
                legalName: process.env.GATSBY_LEGAL_NAME,
                openingHours: process.env.GATSBY_OPENING_HOURS,
                geo: {
                    "@type": "GeoCoordinates",
                    latitude: process.env.GATSBY_LATITUDE,
                    longitude: process.env.GATSBY_LONGITUDE,
                },
                description: "",
                slogan: "",
                address: {
                    "@type": "PostalAddress",
                    addressLocality: process.env.GATSBY_LOCALITY,
                    addressRegion: process.env.GATSBY_REGION,
                    postalCode: process.env.GATSBY_POSTCODE,
                    streetAddress: process.env.GATSBY_ADDRESS
                },
                telephone: process.env.GATSBY_TELEPHONE,
                email: process.env.GATSBY_EMAIL,
                url: process.env.GATSBY_DOMAIN,
            }
        ];

        // Add any custom schema objects being passed through from the page
        if (this.props.schema) {
            this.props.schema.map(item => {
                return schemaOrgJSONLD.push(item);
            });
        }
        return JSON.stringify(schemaOrgJSONLD);
    }

    render() {

        return (
            <Helmet
                encodeSpecialCharacters={false}
                titleTemplate={process.env.GATSBY_TITLE_TEMPLATE}
                htmlAttributes={{ lang : this.props.locale }}
            >
                <title>{this.props.title ? he.decode(this.props.title) : ''}</title>
                <html lang={this.props.locale} />
                <meta name="description" content={this.props.description} />

                {this.props.translations ?
                    <link rel="alternate" href={`${process.env.GATSBY_DOMAIN}${this.props.translations.cy}`} hrefLang="cy"/>
                    : false }

                {this.props.translations ?
                    <link rel="alternate" href={`${process.env.GATSBY_DOMAIN}${this.props.translations.en}`} hrefLang="en"/>
                    : false }

                { this.props.canonical !== undefined  ?  <link rel="canonical" href={process.env.GATSBY_DOMAIN + '/' + this.props.canonical} /> : null }

                <meta name="docsearch:version" content="2.0" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />

                {/* Schema */}
                <script type="application/ld+json">
                    {this.getSchema(process.env.GATSBY_DOMAIN)}
                </script>

                {/* OpenGraph */}
                { this.props.og_title ? <meta property="og:url" content={process.env.GATSBY_DOMAIN + this.props.path} /> : null }
                { this.props.og_title ? <meta property="og:type" content="website" /> : null }
                { this.props.og_title ? <meta property="og:locale" content="en" /> : null }
                { this.props.og_title ? <meta property="og:site_name" content={process.env.GATSBY_SITE_NAME} /> : null }
                { this.props.og_title ? <meta property="og:title" content={this.props.og_title} /> : null }
                { this.props.og_description  ? <meta property="og:description" content={this.props.og_description} /> : null }
                { this.props.og_image  ? <meta property="og:image" content={process.env.GATSBY_DOMAIN + this.props.og_image} /> : null }
                { this.props.og_image  ? <meta property="og:image:width" content="512" /> : null }
                { this.props.og_image  ? <meta property="og:image:height" content="512" /> : null }

                {/* Twitter Card */}
                { this.props.twitter_title ? <meta name="twitter:site" content={process.env.GATSBY_TWITTER} /> : null }
                { this.props.twitter_title ? <meta name="twitter:title" content={this.props.twitter_title} /> : null }
                { this.props.twitter_title ? <meta name="twitter:description" content={this.props.twitter_description} /> : null }
                { this.props.twitter_title ? <meta name="twitter:card" content={this.props.twitter_card_type} /> : null }
                { this.props.twitter_image ? <meta name="twitter:image" content={process.env.GATSBY_DOMAIN + this.props.twitter_image} /> : null }

            </Helmet>
        )
    }
}

export default MetaData
